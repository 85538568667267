import { extend } from "umi-request";

const { REACT_APP_API_URL } = process.env;

interface ObjectInterface {
  [key: string]: any;
}

export const ApiRequest = extend({
  prefix: `${REACT_APP_API_URL}`,
  timeout: 1000 * 60,
});

ApiRequest.interceptors.request.use((url, options): any => {
  const headers: ObjectInterface = {
    "Content-Type": "application/json",
  };

  return {
    url,
    options: { ...options, headers },
  };
});
