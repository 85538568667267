import { DotLoading } from "antd-mobile";

export default function LoadingScreen() {
  return (
    <div className="flex justify-center items-start h-screen">
      <div className="mt-6">
        <DotLoading />
      </div>
    </div>
  );
}
