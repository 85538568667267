import React, { useEffect, useState } from "react";
import { List, InfiniteScroll, PullToRefresh } from "antd-mobile";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import { GetEventsRequest } from "@/services/qr/event.service";
import { EventDto } from "@/services/qr/dto/event/event.dto";
import { useHeader } from "@/context/HeaderContext";
import { TitleCollapse } from "@/components/TitleCollapse";
import { InfiniteScrollContent } from "@/components/InfiniteScrollContent";
import Avatar from "@/components/Avatar";
import { getInitials } from "@/utils/get_initials";

const { REACT_APP_TITLE } = process.env;

const EventList: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle } = useHeader();
  const [events, setEvents] = useState<EventDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any | null>(null);

  const { loading, run } = useRequest(
    () => GetEventsRequest(10, lastEvaluatedKey),
    {
      onSuccess: (data) => {
        setEvents((prevEvents) => {
          const allEvents = [...prevEvents, ...data.events];
          const uniqueEvents = allEvents.filter(
            (event, index, self) =>
              index === self.findIndex((e) => e.uuid === event.uuid)
          );
          return uniqueEvents;
        });

        if (data.last_evaluated_key) {
          setLastEvaluatedKey(data.last_evaluated_key);
        }

        setHasMore(!!data.last_evaluated_key);
      },
      onError: (error) => {
        console.error("Error fetching events:", error);
        setHasMore(false);
      },
    }
  );

  const handleLoadMore = async (): Promise<void> => {
    if (!loading) {
      run();
    }
  };

  const handleClick = (event: EventDto) => {
    navigate(`/${event.prefix}`);
  };

  useEffect(() => {
    setTitle(REACT_APP_TITLE?.toString() || "");
  }, [setTitle]);

  return (
    <PullToRefresh
      onRefresh={async () => {
        setEvents([]);
        setLastEvaluatedKey(null);
        run();
      }}
    >
      <List>
        {events.map((event) => (
          <List.Item
            key={event.uuid}
            onClick={() => handleClick(event)}
            description={
              <span className="text-gray-500 text-sm">{event.start_date}</span>
            }
            prefix={
              <Avatar
                title={getInitials(event.name)}
                tag={event.live ? "live" : ""}
                circle={100}
                color={event.live ? "red" : "gray"}
              />
            }
          >
            <TitleCollapse title={`${event.name}`} />
          </List.Item>
        ))}
      </List>
      <InfiniteScroll loadMore={handleLoadMore} hasMore={hasMore}>
        {(isLoading, failed) => <InfiniteScrollContent hasMore={hasMore} />}
      </InfiniteScroll>
    </PullToRefresh>
  );
};

export default EventList;
