import React from "react";
import { ConfigProvider } from "antd-mobile";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { HeaderProvider } from "@/context/HeaderContext";
import Header from "./layout/header";
import Footer from "./layout/footer";

import Home from "@/pages/index";
import Event from "@/pages/event/index";
import AgeGroup from "@/pages/age_group/index";

import Participant from "@/pages/participant/index";
import SearchParticipant from "@/pages/search_participant";

import esUS from "antd-mobile/es/locales/es-ES";
import ScrollToTop from "@/components/ScrollTo";

const { REACT_APP_TITLE } = process.env;

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{REACT_APP_TITLE}</title>
      </Helmet>
      <HeaderProvider>
        <ConfigProvider locale={esUS}>
          <Router>
            <ScrollToTop />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:prefix" element={<Event />} />
              <Route
                path="/:prefix/grupo-por-edad/:age_group_uuid"
                element={<AgeGroup />}
              />
              <Route
                path="/:prefix/buscar/participante"
                element={<SearchParticipant />}
              />
              <Route path="/:prefix/:number" element={<Participant />} />
            </Routes>
            <Footer />
          </Router>
        </ConfigProvider>
      </HeaderProvider>
    </HelmetProvider>
  );
};

export default App;
