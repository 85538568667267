import { ApiRequest } from "@/services/request";
import { PaginatedAgeGroupDto } from "@/services/qr/dto/age_group/age_group.dto";

export function GetAgeGroupResultsRequest(
  age_group: string,
  limit?: number,
  lastEvaluatedKey?: any
): Promise<PaginatedAgeGroupDto> {
  return ApiRequest(`/age_group/${age_group}`, {
    method: "POST",
    body: JSON.stringify({
      limit,
      last_evaluated_key: lastEvaluatedKey,
    }),
  });
}
