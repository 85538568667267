import { ApiRequest } from "@/services/request";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";

export function GetParticipantResultsRequest(
  prefix: string,
  number: string
): Promise<ParticipantResultDto[]> {
  return ApiRequest(`/participant/${prefix}/${number}`, {
    method: "GET",
  });
}

export function SearchParticipantResultsRequest(
  prefix: string,
  query: string
): Promise<ParticipantResultDto[]> {
  return ApiRequest(`/search/participant`, {
    method: "GET",
    params: {
      prefix: prefix,
      query: query,
    },
  });
}
