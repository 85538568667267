import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import Avatar from "@/components/Avatar";
import { Tag } from "antd-mobile";

interface ParticipantInfoProps {
  result: ParticipantResultDto;
}

export default function ParticipantInfo({
  result,
}: Readonly<ParticipantInfoProps>) {
  return (
    <div className="flex items-center mb-4 min-w-0">
      <div>
        <Avatar
          color={result?.participant_gender}
          title={result?.place_age_group?.toString()}
          circle={result.percent_completed}
          dot={result.percent_completed < 100}
          size="small"
          titleSize="16px"
        />
      </div>

      <div className="flex flex-col w-full min-w-0 ml-3">
        <h2 className="text-lg w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
          {result.participant_name} - <Tag>{result.number}</Tag>
        </h2>
        <p className="text-gray-500 text-xs w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
          {result?.contest_name} {result?.age_group_name}{" "}
        </p>
      </div>
    </div>
  );
}
