import React from 'react';

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="w-full h-2 bg-gray-200 rounded-md">
      <div
        className="h-full rounded-md bg-gray-400 transition-all"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
