import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { List, PullToRefresh, SearchBar } from "antd-mobile";
import { useRequest } from "ahooks";
import { GetEventRequest } from "@/services/qr/event.service";
import { useHeader } from "@/context/HeaderContext";
import LoadingScreen from "@/components/LoadingScreen";
import ErrorResult from "@/components/ErrorResult";
import { AgeGroupDto } from "@/services/qr/dto/event/event.dto";
import { TitleCollapse } from "@/components/TitleCollapse";

const AgeGroupList: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const { prefix } = useParams<{ prefix: string }>();

  const { run, data, loading, error } = useRequest(
    () => GetEventRequest(prefix!!),
    {
      manual: true,
      onError: () => console.error("Error fetching event"),
    }
  );

  useEffect(() => {
    if (prefix) {
      run();
    }
  }, [prefix, run]);

  useEffect(() => {
    setTitle(data?.name ?? "");
    setBack(`/`);
    setLive(data?.live ?? false);

    return () => {
      resetContext();
    };
  }, [setTitle, setBack, resetContext, setLive, data]);

  if (loading) return <LoadingScreen />;

  if (!data && error) return <ErrorResult />;
  if (loading || !data) return <LoadingScreen />;
  if (error) return <ErrorResult />;

  const handleClick = (ageGroup: AgeGroupDto) => {
    navigate(`/${prefix}/grupo-por-edad/${ageGroup.uuid}`);
  };

  const handleSearch = (value: string) => {
    navigate(`/${prefix}/buscar/participante?query=${value}`);
  };

  return (
    <>
      <PullToRefresh
        onRefresh={async () => {
          run();
        }}
      >
        <SearchBar
          placeholder="busca por nombre o número"
          onSearch={handleSearch}
        />
        {data?.contests?.map((contest) => (
          <List
            header={<span className="text-gray-500">{contest.name}</span>}
            key={contest.uuid}
          >
            {contest.age_groups.map((ageGroup) => (
              <List.Item
                key={ageGroup.uuid}
                onClick={() => handleClick(ageGroup)}
              >
                <TitleCollapse title={`${contest.name} ${ageGroup.name}`} />
              </List.Item>
            ))}
          </List>
        ))}
      </PullToRefresh>
      <div className="mb-9" />
    </>
  );
};

export default AgeGroupList;
