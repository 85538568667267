import React from "react";
import {
  ParticipantResultSegmentDto,
  ParticipantResultSegmentResultDto,
} from "@/services/qr/dto/participant/participant.dto";
import { PaceTypeEnum } from "@/services/qr/enum/pace_type.enum";
import { ProgressBar } from "./ProgressBar";
import TimeComponent from "@/components/TimeComponent";

interface SegmentContentProps {
  segment: ParticipantResultSegmentDto;
}

export const SegmentContent: React.FC<SegmentContentProps> = ({ segment }) => {
  return (
    <div className="bg-[#F5F5F5] py-2 px-4 mb-6 rounded-2xl text-[#333333]">
      <div
        className="grid gap-3 text-xs text-center sticky top-0 z-10 bg-[#F5F5F5]"
        style={{ gridTemplateColumns: "50px 40px 2fr 80px" }}
      >
        <div className="py-2 text-xs flex items-center justify-center">
          Vuelta
        </div>
        <div className="py-2 text-xs">
          {segment.pace_type === PaceTypeEnum.KMH ? "Velocidad" : "Ritmo"}
          <p className="text-xs text-gray-500">{segment.pace_type}</p>
        </div>
        <div className="py-2 text-xs flex items-center justify-center">
          {segment.laps_required} / {segment.laps_completed}
        </div>
        <div className="py-2 text-xs flex items-center justify-center">
          Tiempo
        </div>
      </div>
      {segment.results.map(
        (result: ParticipantResultSegmentResultDto, index: number) => {
          const progress = result.unix > 0 ? 100 : 0;

          return (
            <div
              key={segment.uuid + result.lap}
              className="grid gap-3 text-center text-gray-500"
              style={{ gridTemplateColumns: "50px 40px 2fr 80px" }}
            >
              <div className="py-3 overflow-hidden text-ellipsis whitespace-nowrap">
                {index + 1}
              </div>
              <div className="py-3">{result.pace ?? "-"}</div>
              <div className="py-3 flex items-center">
                <ProgressBar progress={progress} />
              </div>
              <div className="py-3">
                <TimeComponent time={result.time} />
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};
