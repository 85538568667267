import { Result } from "antd-mobile";

interface ErrorResultProps {
  title?: any;
  description?: any;
}

export default function ErrorResult({
  title,
  description,
}: Readonly<ErrorResultProps>) {
  return (
    <Result
      status="warning"
      title={title ?? "Oops! No se encontraron resultados"}
      description={description ?? "Intenta más tarde o verifica que exista"}
    />
  );
}
