import React from "react";
import { Footer } from "antd-mobile";

const { REACT_APP_TITLE } = process.env;

const FooterCustom: React.FC = () => {
  return <Footer content={`Power by ${REACT_APP_TITLE}`}></Footer>;
};

export default FooterCustom;
