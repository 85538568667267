import { ApiRequest } from "@/services/request";
import {
  PaginatedEventsDto,
  EventDto,
} from "@/services/qr/dto/event/event.dto";

// get event by prefix
export function GetEventRequest(prefix: string): Promise<EventDto> {
  return ApiRequest(`/event/${prefix}`, {
    method: "GET",
  });
}

// get events via post request if not last_evaluated_key not send
export function GetEventsRequest(
  limit: number,
  lastEvaluatedKey?: any
): Promise<PaginatedEventsDto> {
  return ApiRequest(`/events`, {
    method: "POST",
    body: JSON.stringify({
      limit,
      last_evaluated_key: lastEvaluatedKey,
    }),
  });
}
