import React from "react";
import { Collapse } from "antd-mobile";
import TimeComponent from "@/components/TimeComponent";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import Avatar from "@/components/Avatar";
import { getInitials } from "@/utils/get_initials";
import { SegmentContent } from "./SegmentContent";

interface SegmentTableProps {
  result: ParticipantResultDto;
  disablePadding?: boolean;
}

// Convertir distancia a kilómetros
function distanceToKm(distance: number): number {
  return distance / 1000; // Asegura que el resultado sea una cadena con dos decimales
}

const SegmentTable: React.FC<SegmentTableProps> = ({
  result,
  disablePadding,
}) => {
  const segments = result.segments;

  return (
    <>
      {segments.length > 0 && (
        <>
          {segments.length > 2 ? (
            <Collapse accordion>
              {segments.map((segment, index) => {
                const progress = result.percent_completed;
                return (
                  <Collapse.Panel
                    key={segment.uuid}
                    title={
                      <div className="grid gap-6 grid-cols-2 px-2">
                        <div className="flex flex-col items-start justify-center">
                          <div className="flex items-center  w-full">
                            <Avatar
                              title={getInitials(segment.name)}
                              color="gray"
                              size="small"
                              circle={progress}
                            />

                            <h3 className="text-lg text-[#333333] ml-2 w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                              {distanceToKm(segment.distance)} km {segment.name}
                            </h3>
                          </div>
                        </div>
                        <div className="flex flex-col items-end justify-center text-lg text-[#333333]">
                          <TimeComponent
                            time={segment.total_time}
                            color="#333333"
                          />
                        </div>
                      </div>
                    }
                  >
                    <SegmentContent segment={segment} />
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          ) : (
            segments.map((segment, index) => (
              <div key={segment.uuid}>
                {segments.length > 1 && (
                  <div className="grid grid-cols-2 py-4 px-4">
                    <div className="flex flex-col items-start justify-center">
                      <h3 className="text-lg text-[#333333]">
                        {distanceToKm(segment.distance)} km {segment.name}
                      </h3>
                    </div>
                    <div className="flex flex-col items-end justify-center text-lg text-[#333333]">
                      <TimeComponent
                        time={segment.total_time}
                        color="#333333"
                      />
                    </div>
                  </div>
                )}
                <div className={disablePadding ? "" : "px-4"}>
                  <SegmentContent segment={segment} />
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
};

export default SegmentTable;
