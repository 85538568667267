import React, { createContext, useState, useContext } from "react";

interface HeaderContextProps {
  title: string;
  setTitle: (title: string) => void;
  back: string | null;
  setBack: (path: string | null) => void;
  live: boolean;
  setLive: (live: boolean) => void;
  resetContext: () => void;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeader must be used within a HeaderProvider");
  }
  return context;
};

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [title, setTitle] = useState("");
  const [back, setBack] = useState<string | null>(null);
  const [live, setLive] = useState(false);

  const resetContext = () => {
    setTitle("");
    setBack(null);
    setLive(false);
  };

  return (
    <HeaderContext.Provider
      value={{ title, setTitle, back, setBack, live, setLive, resetContext }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
