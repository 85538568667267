import TimeComponent from "@/components/TimeComponent";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";

interface ContestInfoProps {
  result: ParticipantResultDto;
}

export default function ContestInfo({ result }: Readonly<ContestInfoProps>) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-left sm:text-right text-[#333333] sm:flex-grow items-center">
      <div className="text-center flex-col items-center justify-center hidden sm:flex">
        <h3 className="text-lg">{result.contest_name}</h3>
        <p className="text-gray-500">Distancia</p>
      </div>

      <div className="text-center flex flex-col items-center justify-center">
        <h3 className="text-lg text-[#333333]">
          <TimeComponent
            time={result.total_time}
            textSize="xs"
            color="#333333"
          />
        </h3>
        <p className="text-gray-500">Tiempo Total</p>
      </div>
      <div className="text-center flex flex-col items-center justify-center">
        <h3 className="text-lg text-[#333333]">{result.place_age_group}</h3>
        <p className="text-gray-500">Lugar Categoría</p>
      </div>
    </div>
  );
}
