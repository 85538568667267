import { useEffect, useState } from "react";
import { SearchBar, List, PullToRefresh, Tag } from "antd-mobile";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { SearchParticipantResultsRequest } from "@/services/qr/participant.service";

import ErrorResult from "@/components/ErrorResult";
import LoadingScreen from "@/components/LoadingScreen";
import { ParticipantResultDto } from "@/services/qr/dto/participant/participant.dto";
import { useHeader } from "@/context/HeaderContext";
import { TitleCollapse } from "@/components/TitleCollapse";
import Avatar from "@/components/Avatar";
import TimeComponent from "@/components/TimeComponent";

export default function SearchParticipantResults() {
  const navigate = useNavigate();
  const { setTitle, setBack, setLive, resetContext } = useHeader();
  const [results, setResults] = useState<ParticipantResultDto[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { prefix } = useParams<{ prefix: string }>();

  const [query, setQuery] = useState(searchParams.get("query") ?? "");

  const {
    loading: loadingSearch,
    run: runSearch,
    error: errorSearch,
  } = useRequest(() => SearchParticipantResultsRequest(prefix!, query), {
    onSuccess: (data) => {
      setResults(data);
    },
    onError: (error) => {
      console.error("Error fetching search results:", error);
    },
  });

  useEffect(() => {
    setTitle("Buscar Participante");
    setBack(`/${prefix}`);
    setLive(false);

    return () => {
      resetContext();
    };
  }, [prefix, setTitle, setBack, resetContext, setLive, runSearch]);

  const handleSearch = () => {
    setSearchParams({ query });
    runSearch(); // Ejecuta la búsqueda cuando se presiona Enter o se hace clic en buscar
  };

  const handleClearSearch = () => {
    setQuery("");
    setSearchParams({ query: "" });
    setResults([]); // Limpiar resultados al limpiar el SearchBar
  };

  const handleClick = (participant: ParticipantResultDto) => {
    navigate(`/${prefix}/${participant.number}`);
  };

  // Agrupar resultados por número de participante
  const groupedResults = results.reduce((acc, participant) => {
    const existing = acc.find((p) => p.number === participant.number);
    if (existing) {
      existing.contest_names.push(participant.contest_name);
    } else {
      acc.push({
        ...participant,
        contest_names: [participant.contest_name],
      });
    }
    return acc;
  }, [] as (ParticipantResultDto & { contest_names: string[] })[]);

  return (
    <>
      <PullToRefresh
        onRefresh={async () => {
          setResults([]);
          runSearch();
        }}
      >
        <SearchBar
          placeholder="Busca por nombre o número"
          value={query}
          onChange={setQuery} // Actualiza el estado del query cuando el usuario escribe
          onSearch={handleSearch} // Ejecuta la búsqueda al hacer clic en buscar o presionar Enter
          onClear={handleClearSearch} // Limpia la búsqueda
        />
        {errorSearch && <ErrorResult />}
        {loadingSearch && <LoadingScreen />}
        {!loadingSearch && !errorSearch && groupedResults?.length > 0 && (
          <List>
            {groupedResults.map((participant) => (
              <List.Item
                key={participant.number}
                prefix={
                  <Avatar
                    color={participant.participant_gender}
                    title={participant.place_age_group.toString()}
                    circle={participant.percent_completed}
                    dot={participant.percent_completed < 100}
                  />
                }
                description={<TimeComponent time={participant.total_time} />}
                onClick={() => handleClick(participant)}
              >
                <TitleCollapse
                  title={
                    <>
                      {participant.participant_name} -{" "}
                      <Tag>{participant.number}</Tag>
                    </>
                  }
                />
              </List.Item>
            ))}
          </List>
        )}
      </PullToRefresh>
      <div className="mb-9" />
    </>
  );
}
