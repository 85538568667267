import { DotLoading } from "antd-mobile";
export const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span></span>
          <DotLoading />
        </>
      ) : (
        <span></span>
      )}
    </>
  );
};
