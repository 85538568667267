import { useState, useEffect, useRef } from "react";

export const useScrollDirection = (triggerPixel: number = 10) => {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const lastScrollTop = useRef(window.pageYOffset); // Inicializa con la posición actual del scroll
  const ticking = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset;

      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (currentScrollTop > lastScrollTop.current && currentScrollTop >= triggerPixel) {
            setIsScrollingDown(true);
            setIsScrollingUp(false);
          } else if (currentScrollTop < lastScrollTop.current) {
            setIsScrollingUp(true);
            setIsScrollingDown(false);
          }

          lastScrollTop.current = currentScrollTop;
          ticking.current = false;
        });

        ticking.current = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [triggerPixel]);

  return { isScrollingUp, isScrollingDown };
};
